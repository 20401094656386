@import "../global_variables";


@media screen and (max-width: $usacm-mobile) {
  .org-row {
    flex-direction: column;
  }

  .org-display-container {
    margin-bottom: 15px;
  }

  .org-display-buttons {
    order: 2;
  }

  .org-display-name {
    order: 1;
  }

}

