@import "../global_variables";

.sch-button-container {
  text-align: right;
  margin-bottom: 15px;
}

.sch-button-container button {
  margin-left: 20px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.sch-table {
  width: 100%;
  border: 1px solid #DDD;
  border-spacing: 0;
  border-collapse: collapse;
}

.sch-row {
  height: 50px;
}

.sch-first-col {
  white-space: nowrap;
  border: 1px solid #DDD;
  text-align: left;
  vertical-align: center;
  padding-left: 5px;
}

.sch-col {
  text-align: center;
  vertical-align: center;
  min-width: 50px;
  border: 1px solid #DDD;
  cursor: pointer;
}

.sch-has-abstracts {
  background-color: #D0FFD0;
}

.sch-has-no-abstracts {
  background-color: #FFFFFF;
}

.sch-scheduling-complete {
  font-weight: bold;
  color: #0060C0;
  border: 3px solid #50B050; // overwrites sch-col border
}

.sch-legend {
  margin-top: 5px;
  width: 500px;
  padding: 2px;
}
