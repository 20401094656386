@import "../global_variables";

.invoice-row {
  flex-wrap: nowrap;
  margin-bottom: 10px;
}

.invoice-header {
  font-weight: bold;
}

.invoice-amt {
  min-width: 150px;
  max-width: 180px;
}

.invoice-key {
  min-width: 120px;
  max-width: 220px;
}

.invoice-text {
  min-width: 150px;
  flex-grow: 1;
}

.invoice-action {
  min-width: 370px;
  max-width: 370px;
  white-space: nowrap;
}
