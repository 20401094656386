@import "../global_variables";

.abstract-text {
  /* remove the fixed-width font the pre tag gives us */
  font-family: "Roboto", "Lucida Grande", "DejaVu Sans", "Bitstream Vera Sans", Verdana, Arial, sans-serif;
  font-size: 1rem;
  white-space: pre-wrap;
  padding: 10px;
}

.author {
  padding: 5px 5px 10px 5px;
}

.presenting-author {
  color: green;
  padding-left: 10px;
}