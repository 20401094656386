/*
  Globally defined CSS variables
*/

:root {
  /* Default theme colors - These may be overridden by DB values */
  --usacm-dark-color: #2C343F;
  --usacm-medium-dark-color: #3C444F;
  --usacm-light-color: #B0B0B0;
  --usacm-highlight-color: #FFFFFF;
  --usacm-logo-url: '';
  --usacm-header-url: url('../public/default_header.jpg');

  --usacm-link-color: #1166AA;
  --usacm-card-header-text: #FFFFFF;
  --usacm-card-header-secondary-text: #DDDDDD;
  --usacm-card-header-background: #5C646F;
  --usacm-card-padding: 15px;

  // Bootstrap color overrides
  --usacm-primary-text: #FFFFFF;
  --usacm-primary-background: #2277BB;
  --usacm-primary-hover-text: #FFFFFF;
  --usacm-primary-hover-background: #1166AA;
  --usacm-secondary-text: #FFFFFF;
  --usacm-secondary-background: #777777;
  --usacm-secondary-hover-text: #FFFFFF;
  --usacm-secondary-hover-background: #666666;
  --usacm-disabled-text: #DDDDDD;
  --usacm-disabled-background: #AAAAAA;
}

$usacm-mobile: 1000px;
$usacm-left-menu-width: 280px;


