@import "../global_variables";

.table-buttons {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  margin-bottom: 3px;
}

.table-buttons button {
  margin-top: 3px;
  margin-bottom: 3px;
}
