@import "../global_variables";

.organizer {
  padding: 5px 5px 10px 5px;
}

.main-organizer {
  color: green;
  padding-left: 10px;
}

