@import "../global_variables";

#usacm-busy-container {
  display: none;
}

.busy-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  z-index: 999;
  opacity: 50%;
}

.busy-foreground {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 100px;
  background-color: white;
  z-index: 1000;
  transform: translate(-50%, -50%); // adjust to center
  border-radius: 10px;
  padding: 0;
  text-align: center;
  filter: drop-shadow(5px 10px 5px #222);
}

.busy-content {
  position: relative;
  display: inline-block;
  line-height: 100px;
  width: 100%;
}

.busy-close {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  cursor: pointer;
  border-radius: 5px;
}

.busy-close:hover {
  background-color: #EEE;
  color: #333
}

.busy-spinner {
  vertical-align: middle;
  display: inline-block;
}

.busy-text {
  margin-left: 20px;
  vertical-align: middle;
  display: inline-block;
}
