@import "../global_variables";

.color-picker-input {
  padding-top: 10px !important;
}

.preview-menu {
  padding: 0;
}

.preview-heading {
  text-align: center;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 20px;
  font-weight: bold;
}

.preview-item {
  padding: 10px;
}