@import "../global_variables";

.conf-logo-wide {
  background-color: var(--usacm-medium-dark-color);
  width: 100%;
  text-align: center;
}

.conf-logo-wide img {
  object-fit: contain;
  width: 100%;
  height: 100px;
}

.conf-logo-square {
  margin-left: 15px;
}

.conf-logo-square img {
  height: 100px;
}

.main-menu {
  background-color: var(--usacm-dark-color);
  color: var(--usacm-light-color);
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.menu-title {
  display: flex;
  text-align: center;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  color: var(--usacm-light-color);
  background-color: var(--usacm-medium-dark-color);
}

.conf-title {
  font-weight: bold;
  color: var(--usacm-highlight-color);
  padding-left: 5px;
  padding-right: 5px;
}

.menu-burger {
  display: none;
}

.menu-item {
  margin: 10px;
  padding: 0;
  color: var(--usacm-light-color);
  text-decoration-line: none;
  white-space: nowrap;
}

.menu-item:hover {
  color: var(--usacm-highlight-color);
  font-weight: bold;
}

.menu-item svg {
  padding-right: 10px;
}

.menu-heading {
  margin-top: 5px;
  margin-left: 0;
  margin-right: 0;
  padding: 5px;
  background-color: var(--usacm-medium-dark-color);
  width: 100%;
  text-align: center;
}

.menu-mobile-only {
  display: none;
}

.menu-selected-path {
  color: var(--usacm-highlight-color);
  font-weight: bold;
}

/* --------------- Mobile ------------------------ */

@media screen and (max-width: $usacm-mobile) {
  .conf-logo-wide {
    padding-left: 50px;
  }

  .main-menu {
    align-items: center;
  }

  .menu-title {
    padding-left: 50px;
  }

  .conf-title {
    font-size: 20px;
  }

  .menu-burger {
    display: block;
    position: absolute;
    left: 10px;
    top: 10px;
    width: 40px;
    height: 40px;
  }

  .burger-expanded .menu-item, .burger-collapsed .menu-heading {
    display: block
  }

  .burger-collapsed .menu-item, .burger-collapsed .menu-heading {
    display: none;
  }

  .menu-item {
    font-size: 20px;
    padding: 5px;
  }

  .menu-heading {
    font-size: 22px;
  }

}


