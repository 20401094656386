@import "../global_variables";

.country-select-menu {
  padding: 10px;
  width: 100%;
}

.country-select-filter-input {
  width: 100%;
  padding: 10px;
}

.country-select-item {
  color: black;
}