.ratt-ready {
  border: 3px solid #00FF00;
  border-radius: 10px;
  background: #CCFFCC;
  text-align: center;
  font-size: 35px;
  font-weight: bold;
  height: 100px;
  line-height: 100px; /* vertical centers the text */
}

.ratt-not-ready {
  background: #FFCCCC;
  border-radius: 10px;
  border: 3px solid red;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  height: 100px;
  line-height: 100px; /* vertical centers the text */
}

.ratt-success-box {
  color: black;
  padding: 10px;
  border-radius: 10px;
  border: 3px solid #00FF00;
  margin-top: 20px;
}

.ratt-success-name {
  font-weight: bold;
  text-align: center;
  font-size: 30px;
  padding: 10px;
}

.ratt-success-no-update {
  font-weight: bold;
  font-size: 18px;
  color: #008800;
  text-align: center;
}

.ratt-success-data {
  padding: 10px;
}

.ratt-errors {
  background: #FFEEEE;
  border-radius: 10px;
  border: 3px solid red;
  font-weight: bold;
  color: red;
  text-align: center;
  padding: 10px;
  margin-top: 20px;
}

.ratt-errors-header {
  font-size: 30px;
}

.ratt-errors-text {
  margin-top: 10px;
}

.ratt-test-qr {
  margin-top: 30px;
  text-align: center;
}
