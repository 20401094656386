@import "../global_variables";

.search-result-container {

}

.search-result-schedule {
  display: inline-block;
  float:right;
  border:1px solid black;
  padding: 10px;
  margin-left: 10px;
}