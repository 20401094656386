@import "../global_variables";

.timezone-select-menu {
  padding: 10px;
  width: 100%;
}

.timezone-select-filter-input {
  width: 100%;
  padding: 10px;
}

.timezone-select-item {
  color: black;
}