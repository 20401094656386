@import "../global_variables";

.session-accordion-body {
  max-width: 700px;
  margin: auto;
}

.session-accordion-body .usacm-button-row button {
  margin:10px;
}

.session-timeslot-buttons {
  min-height: 50px;
}

@media screen and (max-width: $usacm-mobile) {

  .session-accordion-body .usacm-button-row {
    margin-top: 10px;
  }

  .session-accordion-body .usacm-button-row button {
    margin:3px;
  }

}