/*
  These styles are applied in App.js and will be available everywhere in the site
  Be careful with your specificity so the styles don't accidentally get applied.
 */
@import "global_variables";

body {
  margin: 0;
  font-family: "Roboto", "Lucida Grande", "DejaVu Sans", "Bitstream Vera Sans", Verdana, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* reboot sets a fat bottom margin, but it looks bad with HTML generated with TinyMCE */
p {
  margin-bottom: 0;
}

.root-container {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

.root-left {
  min-height: 100vh;
  max-width: $usacm-left-menu-width;
  min-width: $usacm-left-menu-width;
  width: $usacm-left-menu-width;
  display: flex;
  flex-direction: row;
  align-content: stretch;
}

.root-right {
  min-height: 100%;
  flex-grow: 1;
  width: 100%;
}

.usacm-container-narrow {
  max-width: 600px;
  padding: 30px;
}

.usacm-container-medium {
  max-width: 900px;
  padding: 30px;
}

.usacm-container-wide {
  padding: 30px;
}

/* This removes the bootstrap strangeness of negative margins */
.usacm-container-wide > .row {
  margin-top: 0;
  margin-left: 0;
}

/* ------------------------ Bootstrap overrides (see global_variables.scss for defaults) ------------------------------------- */

.btn-primary {
  color: var(--usacm-primary-text);
  background-color: var(--usacm-primary-background);
  border-color: var(--usacm-primary-background);
}

.btn-primary:hover, .btn-primary:focus, .btn.btn-primary:active, .btn-primary:active, .btn-primary.active, .open > .dropdown-toggle.btn-primary {
  color: var(--usacm-primary-hover-text);
  background-color: var(--usacm-primary-hover-background);
  border-color: var(--usacm-primary-hover-background);
}

.btn-secondary {
  color: var(--usacm-secondary-text);
  background-color: var(--usacm-secondary-background);
  border-color: var(--usacm-secondary-background);
}

.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active, .btn-secondary.active, .open > .dropdown-toggle.btn-secondary {
  color: var(--usacm-secondary-hover-text);
  background-color: var(--usacm-secondary-hover-background);
  border-color: var(--usacm-secondary-hover-background);
}

.btn-primary[disabled], .bt-secondary[disabled] {
  color: var(--usacm-disabled-text);
  background-color: var(--usacm-disabled-background);
  border-color: var(--usacm-disabled-background);
}

// fix bootstrap button links (buttons that look like links)
button.btn-link:hover {
  background-color: white;
}

// An extra class to override the .btn-primary above
button.btn-link.btn-primary {
  color: var(--usacm-link-color);
  background-color: white;
  border-color: white;
}

button.nav-link {
  color: var(--usacm-link-color);
  background-color: white;
  border-color: white;
}

// Bootstrap tooltip Overrides (see HelpIcon.js)
.usacm-tooltip > .tooltip-inner {
  background-color: #333;
  color: #EEE;
}

.usacm-tooltip > .tooltip-arrow {
  --bs-tooltip-bg: #333;
}

.usacm-tooltip.tooltip.show {
  opacity: 1.0;
}

.usacm-modal-fullscreen div.modal-dialog {
  min-width: 95vw;
}

.usacm-modal-fullscreen div.modal-content {
  min-height: 95vh;
}

// Bootstrap accordion
div.accordion {
  --bs-accordion-active-bg: var(--usacm-dark-color);
  --bs-accordion-active-color: var(--usacm-light-color);
}

// Native checkboxes
.form-check-input:checked {
  background-color: var(--usacm-primary-background);
  border-color: var(--usacm-primary-text);
}

/* ----------------------------------------- USACM Cards (section displays) ------------------------------------- */

.u-card-title {
  background-color: var(--usacm-card-header-background);
  color: var(--usacm-card-header-text);
  font-weight: bold;
  text-align: center;
  padding: var(--usacm-card-padding);
}

.u-card-title-secondary {
  background-color: var(--usacm-card-header-background);
  color: var(--usacm-card-header-secondary-text);
  font-weight: bold;
  text-align: center;
  padding: var(--usacm-card-padding);
}

.u-card-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.u-card-row {
  border-left: 2px solid var(--usacm-card-header-background);
  border-right: 2px solid var(--usacm-card-header-background);
  padding: var(--usacm-card-padding);
}

.u-card-right {
  border-right: 2px solid var(--usacm-card-header-background);
}

.u-card-left {
  border-left: 2px solid var(--usacm-card-header-background);
}

.u-card-subtitle {
  font-weight: bold;
  color: black;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.u-card-text {
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.u-card-bottom {
  border-left: 2px solid var(--usacm-card-header-background);
  border-right: 2px solid var(--usacm-card-header-background);
  border-bottom: 2px solid var(--usacm-card-header-background);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.u-card-bottom-right {
  border-bottom-right-radius: 10px;
}

.u-card-bottom-left {
  border-bottom-left-radius: 10px;
}

.u-card-padding {
  padding: var(--usacm-card-padding);
}


@media screen and (max-width: $usacm-mobile) {
  .u-card-wide-row .col {
    width: 100%;
  }

  .u-card-wide-row .u-card-subtitle {
    display: inline-block;
    text-align: left;
    margin-bottom: 3px;
  }

  .u-card-wide-row .u-card-text {
    display: inline-block;
    text-align: left;
    margin-left: 10px;
    margin-bottom: 3px;
  }
}

/* ------------------------------ Reg Sections --------------------------------------------- */

.section-container {
  margin-bottom: 20px;
}

.section-header {
  display: flex;
  flex-direction: row;
  padding: var(--usacm-card-padding);
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background-color: var(--usacm-card-header-background);
  color: var(--usacm-card-header-text);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.section-child {
  border-left: 2px solid var(--usacm-card-header-background);
  border-right: 2px solid var(--usacm-card-header-background);
  border-radius: 10px;
}

.section-child-content {
  padding-left: 10px;
}

.section-child-footer {
  border-bottom: 2px solid var(--usacm-card-header-background);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 5px;
}

/* ----------------------------------------- Misc ---------------------------------------- */

.table-filter-label {
  margin-top: -10px;
  margin-right: 20px;
  display: inline-block;
}

/* This will hide the ag-grid sort number as it takes up a lot of space */
.ag-header-cell-label .ag-sort-indicator-icon.ag-sort-order {
  display: none;
}

/* Used to emulate the red border box in text inputs */
.usacm-error-box {
  border: 1px solid var(--bs-form-invalid-border-color);
  border-radius: 6px;
}

.usacm-error-message {
  color: red;
}

// This matches the bootstrap form control error style
.usacm-invalid-feedback {
  font-size: 0.875em;
  color: #dc3545;
  margin-top: 0.25rem;
}

.usacm-label {
  font-weight: bold;
}

.usacm-button-row {
  text-align: center;
}

.usacm-button-row button {
  margin: 10px;
}

.usacm-fake-input {
  padding: 15px;
}

/* convert a bootstrap button into a text field */
.usacm-button-as-input {
  padding: 1rem 0.75rem;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  width: 100%;
  text-align: left;
}

.usacm-button-as-input:hover, .usacm-button-as-input:focus, .usacm-button-as-input:active, .usacm-button-as-input:active:hover {
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
}


.usacm-icon-button, .usacm-icon-button:hover, .usacm-icon-button:focus, .usacm-icon-button:active, .usacm-icon-button:active:hover {
  color: black;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
}

.excel-green {
  color: #229922;
}

.pdf-red {
  color: #BB2222;
}

/* --------------- Mobile ------------------------ */
@media screen and (max-width: $usacm-mobile) {
  .root-container {
    flex-direction: column;
  }

  .root-left {
    min-height: auto;
    max-width: 100%;
    min-width: 100%;
    width: 100%;
  }

  .usacm-button-row button {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .mobile-only {
    display: inherit;
  }

  .usacm-container-narrow {
    max-width: 100%;
    padding: 10px;
  }

  .usacm-container-medium {
    max-width: 100%;
    padding: 10px;
  }

  .usacm-container-wide {
    max-width: 100%;
    padding: 10px;
  }

}