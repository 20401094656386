@import "../global_variables";

.pay-cols {
  display: flex;
  flex-direction: row;
}

.pay-col {
  flex-grow: 1;
}

@media screen and (max-width: $usacm-mobile) {
  .pay-cols {
    flex-direction: column;
  }
}